export var DeviceWidth;
(function (DeviceWidth) {
    DeviceWidth[DeviceWidth["Small"] = 576] = "Small";
    DeviceWidth[DeviceWidth["Medium"] = 768] = "Medium";
    DeviceWidth[DeviceWidth["Large"] = 992] = "Large";
    DeviceWidth[DeviceWidth["ExtraLarge"] = 1200] = "ExtraLarge";
})(DeviceWidth || (DeviceWidth = {}));
var MenuDataItemType;
(function (MenuDataItemType) {
    MenuDataItemType["Department"] = "department";
    MenuDataItemType["Category"] = "category";
})(MenuDataItemType || (MenuDataItemType = {}));
